.colWidth {
  @apply px-5 flex-shrink-0;
  width: calc(100vw - 40px);
}

.colWidthMobile {
  @apply px-5 flex-shrink-0;
  width: calc(100vw - 70px);
}

.wrapper {
  @apply w-full overflow-hidden pl-15;
}

.dragger {
  @apply flex flex-shrink-0 w-auto overflow-x-visible;
  touch-action: pan-y;
}
