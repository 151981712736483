.twoColumns {
  flex: 2;
}

.tripTypeButton {
  @apply relative py-10 px-15 text-14 font-bold rounded-t-5;

  &:focus {
    @apply outline-none;
  }
}

.tripTypeButtonSelected {
  @apply bg-gray-light;

  &::after,
  &::before {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    bottom: 0;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSI4IiB2aWV3Qm94PSIwIDAgOCA4IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTAgMFY4SDhDMy41ODE3MiA4IDAgNC40MTgyOCAwIDBaIiBmaWxsPSIjRjhGOEZCIi8+Cjwvc3ZnPgo=');
  }

  &::after {
    right: -8px;
  }

  &:not(:first-child) {
    &::before {
      left: -8px;
      transform: scaleX(-1);
    }
  }
}

.tripTypeButtonFlightClubSelected {
  @apply text-white;
  @apply rounded-t-5;
  @apply bg-white-20;

  &::after,
  &::before {
    background-image: none;
  }
}

.tripTypeButtonFlightClubSelectedDark {
  @apply bg-white-10;
}
