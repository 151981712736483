/* These modal widths are meant to fake them being in a container, row, col,
   etc. since we can't use those inside the modal (since the container is full
   width, clicks on it would not close the modal, even though it's invisible to
   the user) */

@screen md {
  .modalXl {
    width: 90%;
    max-width: 910px;
  }
}

@screen md {
  .modalLg {
    width: 646.5px;
  }
}

@screen lg {
  .modalLg {
    width: 753.33px;
  }
}

@screen xl {
  .modalLg {
    width: 825px;
  }
}

@screen md {
  .modalMd {
    width: 462px;
  }
}

@screen lg {
  .modalMd {
    width: 518.33px;
  }
}

@screen xl {
  .modalMd {
    width: 540px;
  }
}

.overlayClose {
  @apply bg-dark-5 motion-safe:duration-200 ease-out;
}

.overlayOpen {
  @apply bg-dark-80 motion-safe:duration-300 ease-in;
}

.modalClose {
  @apply translate-y-60 opacity-0 motion-safe:duration-200 ease-out;
}

.modalOpen {
  @apply translate-y-0 opacity-100 motion-safe:duration-300 ease-in;
}

.modalCloseRight {
  @apply translate-x-[100%] opacity-0 motion-safe:duration-200 ease-out;
}

.modalOpenRight {
  @apply translate-x-0 opacity-100 motion-safe:duration-300 ease-in;
}

.modalCloseFade {
  @apply pointer-events-none opacity-0 motion-safe:duration-200 ease-out;
}

.modalOpenFade {
  @apply opacity-100 motion-safe:duration-300 ease-in;
}

@screen md {
  .modalClose {
    @apply scale-[0.95] translate-x-0;
  }

  .modalOpen {
    @apply scale-100;
  }
}

.footerClose {
  @apply translate-x-full opacity-0 motion-safe:duration-200 ease-out;
}

.footerOpen {
  @apply translate-x-0 opacity-100 motion-safe:duration-300 ease-in;
}
